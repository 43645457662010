import React from "react";

// Original source: https://freesvg.org/cabbage-26939
// Transformed using https://svg2jsx.com/

function Icon() {
  return (

    <svg viewBox="0 0 500 492" fill="none" xmlns="http://www.w3.org/2000/svg">
    </svg>
  );
}

export default Icon;
